import { authorizedFetch } from '../../helpers/authorizedFetch';
import { formatDate } from '../../helpers/date';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import getIncluded from '../../japi/getIncluded';
import getMultipleIncluded from '../../japi/getMultipleIncluded';
import isResourceCollectionDocument from '../../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../../japi/types/Document';
import { DateFormat } from '../../types';
import { DepartmentResource } from '../Department/Department';
import { transformToDepartment } from '../Department/DepartmentTransformers';
import { DepartmentGroupResource } from '../DepartmentGroup/DepartmentGroup';
import { FetchCollectionResult, FetchResultType } from '../FetchResult';
import { ShiftPlanningResource } from '../ShiftPlanning/ShiftPlanning';
import { transformToShiftPlanning } from '../ShiftPlanning/ShiftPlanningTransformers';
import { TemporaryWorkerResource } from '../TemporaryWorker/TemporaryWorker';
import { transformToTemporaryWorker } from '../TemporaryWorker/TemporaryWorkerTransformers';
import { ShiftIndexUserResource, UserResource } from '../User/User';
import { ShiftIndex, ShiftResource } from './Shift';
import { transformToShiftIndex } from './ShiftTransformers';

let getShiftsIndexApiCallAbortController = new AbortController();

export const getShiftsIndexCall = async (
    startDate: Date,
    endDate: Date,
): Promise<FetchCollectionResult<ShiftIndex[], string>> => {
    getShiftsIndexApiCallAbortController.abort();
    getShiftsIndexApiCallAbortController = new AbortController();

    const includes = [
        'department',
        'department.group',
        'shiftPlannings',
        'shiftPlannings.user',
    ];

    try {
        const url = generateApiUrl({
            endpoint: '/api/v2/shifts',
            queryParams: {
                startDate: formatDate(startDate, DateFormat.apiParamsDateTime),
                endDate: formatDate(endDate, DateFormat.apiParamsDateTime),
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, { signal: getShiftsIndexApiCallAbortController.signal });

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<ShiftResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const shifts = doc.data.map(shift => {
            const departmentResource = getIncluded(doc, shift, 'department') as DepartmentResource;
            const departmentGroupResource = getIncluded(doc, departmentResource, 'group') as DepartmentGroupResource;
            const department = transformToDepartment(departmentResource, departmentGroupResource);

            const shiftPlanningsResource = getMultipleIncluded(doc, shift, 'shiftPlannings') as ShiftPlanningResource[];
            const shiftPlannings = shiftPlanningsResource.map(shiftPlanningResource => {
                const userResource = getIncluded(doc, shiftPlanningResource, 'user') as ShiftIndexUserResource;

                return transformToShiftPlanning(shiftPlanningResource, userResource);
            });

            const temporaryWorkersResource = getMultipleIncluded(doc, shift, 'temporaryWorkers') as TemporaryWorkerResource[];
            const temporaryWorkers = temporaryWorkersResource.map(temporaryWorkerResource => transformToTemporaryWorker(temporaryWorkerResource, shift));

            const previouslyPlannedUsersResource = getMultipleIncluded(doc, shift, 'previouslyPlannedUsers') as UserResource[];

            return transformToShiftIndex(
                shift,
                department,
                shiftPlannings,
                temporaryWorkers,
                previouslyPlannedUsersResource,
            );
        });

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: shifts,
        };
    } catch (error) {
        console.error('[getShiftsIndexCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
