import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import isResourceCollectionDocument from '../../japi/guards/isResourceCollectionDocument';
import { JapiDocument } from '../../japi/types/Document';
import { FetchCollectionResult, FetchResultType } from '../FetchResult';
import { TimerUser, UserResource } from './User';
import { transformToTimerUser } from './UserTransformers';

export const getTimerUsersApiCall = async (): Promise<FetchCollectionResult<TimerUser[], string>> => {
    try {
        const url = generateApiUrl({ endpoint: '/api/v2/users' });

        const response = await authorizedFetch(url);

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<UserResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const users = doc.data.map(transformToTimerUser);

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: users,
        };
    } catch (error) {
        console.error('[getTimerUsersApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
