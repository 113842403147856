import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { getTimerUsersApiCall } from '../../entities/User/UserService';
import { TypedDispatch } from '../../store';
import { setError, setIsLoading, setTimerUsers } from './usersReducer';

export const fetchTimerUsers = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError());

    try {
        const response = await getTimerUsersApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response));

            return;
        }

        dispatch(setTimerUsers(response.data));
    } catch (error) {
        console.error('[fetchTimerUsers]', error);
    }

    dispatch(setIsLoading(false));
};
