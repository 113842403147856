import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { ClockedInTrack } from '../../entities/Track/Track';
import { getClockedInTracksV3ApiCall } from '../../entities/Track/TrackService';
import { transformClockedInTrackV3ToClockedInTrack } from '../../entities/Track/TrackTransformers';
import { TimerUser } from '../../entities/User/User';

export const fetchClockedInTracks = async (users: TimerUser[]): Promise<ClockedInTrack[]> => {
    try {
        const response = await getClockedInTracksV3ApiCall();

        if (!isFetchCollectionResultSuccessful(response)) {
            throw new Error(`[fetchClockedInTracksHelper]: ${response.error}`);
        }

        response.data.forEach(clockedInTrackV3 => {
            if (!users.some(user => user.id === clockedInTrackV3.userId)) {
                console.error(`Clocked in track user with id ${clockedInTrackV3.userId} is not found in users store.`);
            }
        });

        const clockedInTracks = response.data
            .filter(clockedInTrackV3 => users.some(user => user.id === clockedInTrackV3.userId))
            .map(clockedInTrackV3 => {
                const employeeNumber = users.find(user => user.id === clockedInTrackV3.userId)?.employeeNumber as number;
                return transformClockedInTrackV3ToClockedInTrack(clockedInTrackV3, employeeNumber);
            });

        if (process.env.REACT_APP_APP_ENV !== 'production') {
            console.log('Clocked in tracks added to tracks store:');
        }

        return clockedInTracks;
    } catch (error) {
        console.error(error);

        throw new Error('fetchClockedInTracksHelper');
    }
};
