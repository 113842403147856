import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import { FetchCollectionResult, FetchResult, FetchResultType } from '../FetchResult';
import { ClockedInTrackResourceV3, ClockedInTrackV3 } from './Track';
import { transformToClockedInTrackV3 } from './TrackTransformers';

export const getClockedInTracksV3ApiCall = async (): Promise<FetchCollectionResult<ClockedInTrackV3[], string>> => {
    try {
        const url = generateApiUrl({
            endpoint: '/api/v3/tracks/clocked-in',
        });

        const response = await authorizedFetch(url);
        const doc: ClockedInTrackResourceV3[] = await response.json();

        if (!doc) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const tracks = doc.map(transformToClockedInTrackV3);

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: tracks,
        };
    } catch (error) {
        console.error('[getClockedInTracksV3ApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getTrackDivergence = async (trackId: string): Promise<FetchResult<boolean, string>> => {
    try {
        const url = generateApiUrl({
            endpoint: `/api/v3/tracks/divergent/${trackId}`,
        });

        const response = await authorizedFetch(url);
        const doc: boolean = await response.json();

        if (typeof doc !== 'boolean') {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: doc,
        };
    } catch (error) {
        console.error('[getTrackDivergence]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
