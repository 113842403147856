import { Dispatch } from 'react';

import { isFetchResult, isFetchResultSuccessful } from '../../entities/FetchResult';
import { ClockedInTrack } from '../../entities/Track/Track';
import { getTrackDivergence } from '../../entities/Track/TrackService';
import { CheckInRecord, CheckOutRecord } from '../../entities/TrackRecord/TrackRecord';
import { delay } from '../../helpers/delay';
import { ReducerGetter, TypedDispatch } from '../../store';
import { Reducers } from '../reducers';
import { setError } from '../settings/settingsReducer';
import { fetchClockedInTracks } from '../tracks/tracksActions';
import { setTracks } from '../tracks/tracksReducer';
import { logout } from '../user/userReducer';
import { sendRecordsAndClearStore } from './trackRecordsHelpers';
import {
    setIsLoading,
    setIsSuccessful,
    setTrackRecords,
} from './trackRecordsReducer';

export const addCheckInTrackRecord = (
    isOffline: boolean,
    employeeNumber: number,
    storeApiToken: Dispatch<string | undefined>,
    serverApiDateTime: string,
    shiftId?: string,
) => async (dispatch: TypedDispatch, getState: () => Reducers): Promise<void> => {
    const { appReducer, trackRecordsReducer, usersReducer } = getState();
    const { isOnline } = appReducer;
    const { users } = usersReducer;

    const checkInRecord: CheckInRecord = {
        ...(isOffline && { isOffline }),
        startDate: serverApiDateTime,
        employeeNumber,
        shiftId,
    };

    const updatedCheckInTracks = [
        ...trackRecordsReducer.trackRecords,
        checkInRecord,
    ];

    dispatch(setIsSuccessful(false));
    dispatch(setIsLoading(true));
    dispatch(setError(undefined));

    await delay(2000);

    dispatch(setIsLoading(false));
    dispatch(setIsSuccessful(true));

    if (isOnline) {
        await sendRecordsAndClearStore(updatedCheckInTracks, dispatch);
        const clockedInTracks = await fetchClockedInTracks(users);

        dispatch(setTracks(clockedInTracks));
        storeApiToken(undefined);
        dispatch(logout());

        return;
    }

    await delay(1000);

    if (process.env.REACT_APP_APP_ENV !== 'production') {
        console.log(`Check in record for employee #${employeeNumber} added to tracks store:`);
    }

    dispatch(setTrackRecords(updatedCheckInTracks));
    dispatch(logout());
};

export const addCheckOutTrackRecord = (
    isOffline: boolean,
    employeeNumber: number,
    startDate: string,
    endDate: string,
    shiftId?: string,
    trackId?: string,
) => async (dispatch: TypedDispatch, getState: () => Reducers): Promise<void> => {
    const { trackRecordsReducer, tracksReducer } = getState();

    dispatch(setIsSuccessful(false));
    dispatch(setIsLoading(true));
    dispatch(setError(undefined));

    try {
        const [response] = await Promise.all([
            (trackId && !isOffline) && getTrackDivergence(trackId),
            delay(1000),
        ]);
        const isDivergent = isFetchResult<boolean, string>(response) && isFetchResultSuccessful(response) && response.data;

        const checkOutRecord: CheckOutRecord = {
            ...(isOffline && { isOffline }),
            isDivergent,
            employeeNumber,
            startDate,
            endDate,
            shiftId,
            trackId,
        };

        const updatedTrackRecords: (CheckInRecord | CheckOutRecord)[] = [
            ...trackRecordsReducer.trackRecords,
            checkOutRecord,
        ];
        const updatedClockedInTracks: ClockedInTrack[] = tracksReducer.tracks.filter(track => track.id !== trackId);

        if (process.env.REACT_APP_APP_ENV !== 'production') {
            console.log(`Check out record for employee #${employeeNumber} added to tracks store:`);
            if (trackId) {
                console.log(`Track ${trackId} removed from tracks store:`);
            }
        }

        dispatch(setTrackRecords(updatedTrackRecords));
        dispatch(setTracks(updatedClockedInTracks));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[addCheckOutTrackRecord]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateLastCheckOutRecord = (checkOutRecord: CheckOutRecord) => async (dispatch: TypedDispatch, getState: ReducerGetter): Promise<void> => {
    const { trackRecordsReducer } = getState();

    const trackRecords = [...trackRecordsReducer.trackRecords];
    trackRecords.pop();

    const updatedTrackRecords: (CheckInRecord | CheckOutRecord)[] = [
        ...trackRecords,
        checkOutRecord,
    ];

    if (process.env.REACT_APP_APP_ENV !== 'production') {
        console.log(`Check out record for employee #${checkOutRecord.employeeNumber} added to tracks store:`);
    }

    dispatch(setTrackRecords(updatedTrackRecords));
};
