import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { getShiftsIndexCall } from '../../entities/Shift/ShiftService';
import { addDays, getStartOfWorkDay } from '../../helpers/date';
import { TypedDispatch } from '../../store';
import { setError, setIsLoading, setUserShifts } from './shiftsReducer';

export const fetchShifts = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError());

    try {
        const response = await getShiftsIndexCall(
            getStartOfWorkDay(new Date()),
            addDays(new Date(), 7),
        );

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response));

            return;
        }

        dispatch(setUserShifts(response.data));
    } catch (error) {
        console.error('[fetchShiftsForUser]', error);
    }

    dispatch(setIsLoading(false));
};
