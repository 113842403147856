import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { generateApiUrl } from '../../helpers/url';
import { AccessToken } from '../AccessToken';
import { FetchResult, FetchResultType } from '../FetchResult';
import { AuthenticatedUser, UserProfileV3Resource } from '../User/User';
import { transformToAuthenticatedUser } from '../User/UserTransformers';

export const fetchRefreshToken = async (refreshToken: string): Promise<FetchResult<AccessToken, string>> => {
    try {
        const stringifiedRefreshToken = JSON.stringify(refreshToken);

        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/token/refresh`, {
            body: JSON.stringify({ refresh_token: JSON.parse(stringifiedRefreshToken) }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                TENANT: process.env.REACT_APP_TENANT || window.location.hostname,
            },
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: await response.json(),
        };
    } catch (error) {
        console.error('[fetchRefreshToken]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const authenticateEmployeeApiCall = async (employeeNumber: number, pincode: string): Promise<FetchResult<AccessToken, string>> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v2/auth/token/employee`, {
            body: JSON.stringify({ employee_number: employeeNumber, pincode }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                TENANT: process.env.REACT_APP_TENANT || window.location.hostname,
            },
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: await response.json(),
        };
    } catch (error) {
        console.error('[authenticateEmployeeApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getAuthenticatedUserApiCall = async (): Promise<FetchResult<AuthenticatedUser, string>> => {
    try {
        const url = generateApiUrl({
            endpoint: '/api/v3/users/profile/me',
        });

        const response = await authorizedFetch(url);

        const doc = await response.json();

        if (!doc) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = doc as UserProfileV3Resource;
        const user = transformToAuthenticatedUser(data);

        return {
            status: 200,
            type: FetchResultType.Success,
            data: user,
        };
    } catch (error) {
        console.error('[getAuthenticatedUserApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getMasterCodeApiCall = async (masterCode: string): Promise<FetchResult<boolean, string>> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v3/timer/master-code`, {
            body: JSON.stringify({ masterCode }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                TENANT: process.env.REACT_APP_TENANT || window.location.hostname,
            },
        });

        const doc = await response.json();

        if (!doc || doc.code !== 200) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: true,
        };
    } catch (error) {
        console.error('[getMasterCodeApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
